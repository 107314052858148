import { UserProfileAgeRange } from '../../types/models';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';

export const defaultStylizedAvatarId = 'Shy librarian';
export const defaultStylizedMaleAvatarId = 'Powerful businessman';

export const femaleAvatars = {
  'Shy librarian': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_shy_librarian_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_shy_librarian.png`,
  },
  'Beauty queen': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_beauty_queen_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_beauty_queen.png`,
  },
  'Anime girl': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_anime_girl_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_anime_girl.png`,
  },
  'Fantasy fairy': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_fantasy_fairy_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_fantasy_fairy.png`,
  },
  'Girl next door': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_girl_next_door_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_girl_next_door.png`,
  },
  'Retro housewife': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_retro_housewife_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_retro_housewife.png`,
  },
  'Kinky goth': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_kinky_goth_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/f_ava_kinky_goth.png`,
  },
};

export const maleAvatars = {
  'Powerful businessman': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_powerful_businessman_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_powerful_businessman.png`,
  },
  'Strong defender': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_strong_defender_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_strong_defender.png`,
  },
  'Dangerous outlaw': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_dangerous_outlaw_2_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_dangerous_outlaw_2.png`,
  },
  'Guy next door': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_guy_next_door_3_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_guy_next_door_3.png`,
  },
  'Gothic vampire': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_gothic_vampire_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_gothic_vampire.png`,
  },
  'Futuristic robot': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_futuristic_robot_2_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_futuristic_robot_2.png`,
  },
  'Rebel Artist': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_rebel_artist_2_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_rebel_artist_2.png`,
  },
  'Steampunk mechanic': {
    preview: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_steampunk_mechanic_n_preview.png`,
    image: `${MAIN_PAGE_URL_BASE}/funnel/m_ava_steampunk_mechanic.png`,
  },
};

export const stylizedAvatars = {
  ...femaleAvatars,
  ...maleAvatars,
};

export const realisticAvatarsToStylized = {
  'AI bro female': defaultStylizedAvatarId,
  'AI Partner female': defaultStylizedAvatarId,
  'AI Therapist female': defaultStylizedAvatarId,
  'AI Enlightening Guide female': defaultStylizedAvatarId,

  'AI bro male': defaultStylizedMaleAvatarId,
  'AI Partner male': defaultStylizedMaleAvatarId,
  'AI Therapist male': defaultStylizedMaleAvatarId,
};

export const AGE_MAX_VALUE = '>65';

export const ageRanges: { label: string; value: UserProfileAgeRange }[] = [
  {
    label: 'Under 18',
    value: '<18',
  },
  {
    label: '18-24',
    value: '18-24',
  },
  {
    label: '25-34',
    value: '25-34',
  },
  {
    label: '35-44',
    value: '35-44',
  },
  {
    label: '45-54',
    value: '45-54',
  },
  {
    label: '55-64',
    value: '55-64',
  },
  {
    label: '65 and over',
    value: AGE_MAX_VALUE,
  },
] as const;
