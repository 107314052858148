import {
  UseInputField,
  ValidateConfig,
  fieldAtom,
  formAtom,
  useFieldActions,
  useForm,
} from 'form-atoms';
import { atom } from 'jotai';
import { atomWithReset, atomWithStorage, useResetAtom } from 'jotai/utils';
import { GenderValues, PronounsValues, StorageKeys } from '../../types/enums';
import { CustomizationVariationItem } from '../../types/models';
import { defaultStylizedAvatarId } from './consts';

function required({ value, touched }: ValidateConfig<string>) {
  if (!touched) return;
  return !!value ? [] : ['Required.'];
}

export function isSubmitDisabled(
  ...fields: UseInputField<React.HTMLInputTypeAttribute, string>[]
) {
  return fields.some((field) => {
    if (
      !!field.actions.validate &&
      !field.state.touched &&
      !field.state.dirty
    ) {
      return true;
    }
    return field.state.errors.length > 0;
  });
}

// This is a new approach for us, need to figure out:
// - do we need form-atoms or should we stick with jotai atoms?
// - should we use nested atoms or other more complex structures?
// - should we use any of this at all? :)

export const avatarIdAtom = atomWithReset<string | null>(null);
avatarIdAtom.debugLabel = 'Auth/avatarIdAtom';

export const stylizedAvatarIdAtom = atomWithReset<string>(
  defaultStylizedAvatarId,
);
stylizedAvatarIdAtom.debugLabel = 'Auth/stylizedAvatarIdAtom';

export const emailAtom = fieldAtom({ value: '', validate: required });
emailAtom.debugLabel = 'Auth/emailAtom';

export const passwordAtom = fieldAtom({
  value: '',
  validate(state) {
    if (!state.touched) return;
    if (state.value.length < 8)
      return ['Password must contain at least 8 characters.'];
    return [];
  },
});
passwordAtom.debugLabel = 'Auth/passwordAtom';

export const authAtom = formAtom({
  email: emailAtom,
  password: passwordAtom,
});
authAtom.debugLabel = 'Auth/authAtom';

export const tokenLoginAtom = atom<{
  error: unknown;
  isLoading: boolean;
  params: URLSearchParams | null;
}>({
  error: null,
  isLoading: false,
  params: null,
});
tokenLoginAtom.debugLabel = 'Auth/tokenLoginAtom';

export const nameAtom = fieldAtom({ value: '', validate: required });
nameAtom.debugLabel = 'Auth/nameAtom';

export const pronounsAtom = atomWithReset<PronounsValues>(PronounsValues.He);
pronounsAtom.debugLabel = 'Auth/pronounsAtom';

export const dateAtom = atomWithReset<Date | undefined>(new Date('2000/01/01'));
dateAtom.debugLabel = 'Auth/dateAtom';

export const interestsAtom = atomWithReset<string[]>([]);
interestsAtom.debugLabel = 'Auth/interestsAtom';

export const replikaNameAtom = fieldAtom({ value: '', validate: required });
replikaNameAtom.debugLabel = 'Auth/replikaNameAtom';

export const replikaPronounsAtom = atomWithReset<GenderValues>(
  GenderValues.NonBinary,
);
replikaPronounsAtom.debugLabel = 'Auth/replikaPronounsAtom';

export const accountPasswordAlreadyAddedAtom = atomWithStorage<boolean>(
  StorageKeys.PASSWORD_ALREADY_ADDED,
  false,
  undefined,
  { getOnInit: true },
);
accountPasswordAlreadyAddedAtom.debugLabel =
  'Auth/accountPasswordAlreadyAddedAtom';

export type Customization = {
  hair: CustomizationVariationItem | null;
  eye: CustomizationVariationItem | null;
  skin: CustomizationVariationItem | null;
};

export const customizationAtom = atomWithReset<Customization>({
  hair: null,
  eye: null,
  skin: null,
});
customizationAtom.debugLabel = 'Auth/customizationAtom';

export function mergeVariations(
  customization: Customization,
  variations: CustomizationVariationItem[],
) {
  let merged = [...variations];

  for (let key of ['hair', 'eye', 'skin']) {
    let unityCategory = key === 'skin' ? 'style' : key === 'eye' ? 'eyes' : key;
    if (customization[key]) {
      merged = merged.filter((v) => v.unity_category !== unityCategory);
      merged.push(customization[key]);
    }
  }

  return merged;
}

export function useResetAuthAtoms() {
  const authActions = useForm(authAtom);
  const nameActions = useFieldActions(nameAtom);
  const resetPronouns = useResetAtom(pronounsAtom);
  const resetDate = useResetAtom(dateAtom);
  const resetInterests = useResetAtom(interestsAtom);
  const replikaNameActions = useFieldActions(replikaNameAtom);
  const resetReplikaPronouns = useResetAtom(replikaPronounsAtom);
  const resetAvatarId = useResetAtom(avatarIdAtom);
  // const resetAvatarVariations = useResetAtom(avatarDefaultVariationsAtom);
  const resetCustomization = useResetAtom(customizationAtom);

  return () => {
    authActions.reset();
    nameActions.reset();
    resetPronouns();
    resetDate();
    resetInterests();
    replikaNameActions.reset();
    resetReplikaPronouns();
    resetAvatarId();
    // resetAvatarVariations();
    resetCustomization();
  };
}
